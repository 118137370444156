import {createRoot} from 'react-dom/client';
import 'normalize.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './utils/theme/theme.scss';
import "primereact/resources/primereact.min.css"; //core css
import 'react-toastify/dist/ReactToastify.css';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {enableMapSet} from "immer";
import {Error} from "./Error"
import LogRocket from "logrocket";


enableMapSet();

LogRocket.init('lms/lms');

const root = createRoot(document.getElementById('root')!);

root.render(
    <Error>
		<BrowserRouter>
			<div className="he-bg--primary w-full h-full flex justify-content-center">
				<ToastContainer />
				<App />
			</div>
		</BrowserRouter>
    </Error>

)
