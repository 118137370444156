import React from "react";
import styled from "styled-components";
import {SignatureData} from "@Pages/SignatureData";
import {Route, Routes} from "react-router-dom";

const MobileApp = styled.div`
  width: 100%;
  height: 100%;
`;

export const App : React.FC = () => {

    return (
        <MobileApp className="">
				<Routes>
					<Route path="/signature/:user_id/:signature_id" element={<SignatureData />}/>
				</Routes>
        </MobileApp>
    )
}
