import {Unsubscribe} from "firebase/firestore";
import {
    getAuth,
    connectAuthEmulator,
    browserLocalPersistence,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    onAuthStateChanged,
    setPersistence,
    signOut,
    signInWithPopup,
    Auth,
    GoogleAuthProvider,
    NextOrObserver,
    User,
    UserCredential
} from "firebase/auth";
import {FirebaseApp} from "@firebase/app";


const provider = new GoogleAuthProvider();


export class FirebaseAuth {
    private auth: Auth

    constructor(app: FirebaseApp, emulator ?: {
        url: string
    }) {
        this.auth = getAuth(app);
        void setPersistence(this.auth, browserLocalPersistence);
        if (emulator) {
            connectAuthEmulator(this.auth, emulator.url, {
				disableWarnings: true
            });
        }
    }

    // Auth
    public createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
        return createUserWithEmailAndPassword(this.auth, email, password)
    }

    public signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
        return signInWithEmailAndPassword(this.auth, email, password)
    }

    public sendPasswordResetEmail(email: string): Promise<void> {
        return sendPasswordResetEmail(this.auth, email)
    }

    public sendEmailVerification(): Promise<void> {
        if (this.auth?.currentUser) {
            return sendEmailVerification(this.auth.currentUser)
        }
        return new Promise((a, reject) => reject("not currentUser"))
    }

    public onAuthStateChanged(next: NextOrObserver<User>): Unsubscribe {
        return onAuthStateChanged(this.auth, next);
    }

    /**
     * Get token in return result ->
     *  import {  GoogleAuthProvider } from "firebase/auth";
     *  const credential = GoogleAuthProvider.credentialFromResult(result);
     *  const token = credential.accessToken;
     *
     *
     *
     * @private
     */
    public signInWithPopup(): Promise<UserCredential> {
        return signInWithPopup(this.auth, provider)
    }

    public signOut(): Promise<void> {
        return signOut(this.auth)
    }


}
