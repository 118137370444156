import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useApi} from "@Hooks/api";
import {SignatureExtended} from "@Types/Resource";
import styled from "styled-components";
import logoMobileo from "@Assets/images/logoMobileo.svg";
import {ResponsiveProps, useBreakpoint} from "@Utils/responsive.utils";
import {DateTime} from "luxon";

export type SignatureDataProps = Record<string, never>
export const SignatureData: React.FC<SignatureDataProps> = () => {

	const params = useParams<{ user_id: string, signature_id: string }>();
	const api = useApi();

	const [signatureData, setSignatureData] = useState<SignatureExtended>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (params.signature_id && params.user_id) {
			setLoading(true)
			api.pdf_call_getSignatureData({
				signature_id: params.signature_id,
				user_id: params.user_id
			}).then(data => {
				if (data.result === "ok") {
					setSignatureData(data.signature);
				}
			}).finally(() => {
				setLoading(false)
			})
		}
	}, [params.user_id, params.signature_id]);

	const responsive = useBreakpoint();

	return (
		<div className="SignatureData w-full h-full flex justify-content-center align-items-center">
			{loading ? <i className="pi pi-spin pi-spinner"/> :
				signatureData &&
                <Page className="w-full h-full flex justify-content-start align-items-center flex-column">
                    <Header className="w-full">
                        <img src={logoMobileo}/>
                    </Header>
                    <MainContainer className="flex flex-column" responsive={responsive}>
                        <div className="he-header--h1 mt-5">
                            Traçabilité signature
                        </div>
                        <Card className="mt-3">
                            <div className="he-header--h3">Informations de l'utilisateur</div>
                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
                                    ID
                                </div>
                                <div className="he-paragraph--regular gray-900">
				                    {signatureData.user_id}
                                </div>
                            </div>
                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
									Nom et prénom
                                </div>
                                <div className="he-paragraph--regular gray-900">
				                    {signatureData.user.lastname} {signatureData.user.firstname}
                                </div>
                            </div>
                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
									E-mail
                                </div>
                                <div className="he-paragraph--regular gray-900">
	                                {signatureData.user.email}
                                </div>
                            </div>
                        </Card>
                        <Card className="mt-3">
                            <div className="he-header--h3">Informations de la signature</div>
                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
                                    Signature
                                </div>
                                <div className="he-paragraph--regular gray-900 select-none">
									<img src={signatureData.signature_data} height={60}/>
                                </div>
                            </div>
                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
                                    Lieu
                                </div>
                                <div className="he-paragraph--regular gray-900">
			                        {typeof signatureData.meta.place === "string" && signatureData.meta.place}
                                </div>
                            </div>

                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
                                    Date de signature
                                </div>
                                <div className="he-paragraph--regular gray-900">
			                        {DateTime.fromISO(signatureData.created_at).toFormat("FFF")}
                                </div>
                            </div>

	                        <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
                                    Empreinte
                                </div>
                                <div className="he-paragraph--regular gray-900" style={{wordBreak: "break-all"}}>
				                    {signatureData.hash}
                                </div>
                            </div>
                            <div className="flex flex-column">
                                <div className="he-paragraph--small gray-500">
                                    IP
                                </div>
                                <div className="he-paragraph--regular gray-900">
			                        {typeof signatureData.meta.ip === "string" && signatureData.meta.ip}
                                </div>
                            </div>
                        </Card>
                    </MainContainer>
                </Page>
			}
		</div>
	)
}

const Page = styled.div`
  background: #F2F5F6;
  overflow: auto;
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 50px;
  height: 54px;
  background: #003451;
`
const MainContainer = styled.div<ResponsiveProps>`
	  width: ${props => props.responsive.isDesktop || props.responsive.small ? "650px" : "calc(100% - 32px)"}
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px;
  gap: 22px;
  background: #FFFFFF;
  border-radius: 10px;
`
