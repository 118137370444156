import {useWindowSize} from "react-use";
import {useMemo} from "react";

const PHONE = 425;
const TABLET = 768;
const SMALL = 1140;
const MEDIUM = 1440;
const LARGE = 1920;

export interface ResponsiveProps {
	responsive: {
		phone: boolean,
		tablet: boolean,
		small: boolean,
		medium: boolean,
		large: boolean,
		xl: boolean,
		isTablet: boolean,
		isDesktop: boolean
	}
}

export const useBreakpoint = () => {

	const {width} = useWindowSize();

	const phone = useMemo(() => width < PHONE, [width]);
	const tablet = useMemo(() => width >= PHONE && width < TABLET, [width]);
	const small = useMemo(() => width >= TABLET && width < SMALL, [width]);
	const medium = useMemo(() => width >= SMALL && width < MEDIUM, [width]);
	const large = useMemo(() => width >= MEDIUM && width < LARGE, [width]);
	const xl = useMemo(() => width >= LARGE, [width]);

	const isTablet = useMemo(() => tablet || small, [tablet, small]);
	const isDesktop = useMemo(() => medium || large || xl, [xl, medium, large]);

	return {
		phone,
		tablet,
		small,
		medium,
		large,
		xl,
		isTablet,
		isDesktop,
		width
	}

}
